<template>
    <div>
        <p class="text-secondary mb-1">
            <span class="spinner-border spinner-border-sm mr-1"
                  role="status"
                  v-if="isLoading">
            </span>
            <small>Activity</small>
        </p>
        <div>
            <v-select class="review-details-selector form-control h-auto bg-light rounded-xl shadow-sm py-0"
                      v-bind:disabled="!organizer"
                      v-bind:options="activities"
                      v-bind:get-option-key="({ _id }) => _id"
                      v-bind:get-option-label="({ title }) => title"
                      v-bind:reduce="({ _id }) => _id"
                      v-model="activity"
                      v-on:search="onSearch">
                <template v-slot:option="{ title, startDate, startTime, }">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <p class="text-secondary mb-1">
                                {{ startDate ? format(new Date(startDate), "PP") : "" }} {{ startTime ? format(new Date(startTime), "h:mm aa") : "" }}
                            </p>
                            <h5 class="font-weight-bold mb-0">
                                {{ title }}
                            </h5>
                        </div>
                    </div>
                </template>
                <template v-slot:selected-option="{ title, startDate, startTime, }">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <p class="text-secondary mb-1">
                                {{ startDate ? format(new Date(startDate), "PP") : "" }} {{ startTime ? format(new Date(startTime), "h:mm aa") : "" }}
                            </p>
                            <h5 class="font-weight-bold mb-0">
                                {{ title }}
                            </h5>
                        </div>
                    </div>
                </template>
            </v-select>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import format from "date-fns/format";
import { getActivitiesByOrganizer } from "@/workers/activity.worker";
export default {
    name: "Activity",
    components: {
        vSelect,
    },
    props: {
        value: {
            type: String,
        },
        organizer: {
            type: String,
        },
    },
    data () {
        return {
            format,

            isLoading: false,
            activities: [],
        };
    },
    computed: {
        activity: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            }
        },
    },
    methods: {
        async loadActivitiesByOrganizer () {
            try {
                this.isLoading = true;
                const { data, } = await getActivitiesByOrganizer(this.organizer);
                this.activities = data;
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        onSearch () {

        },
    },
    async mounted () {
        if (!this.organizer) {
            return;
        }
        this.loadActivitiesByOrganizer();
    },
    watch: {
        async organizer (newVal) {
            if (!newVal) {
                return;
            }
            this.loadActivitiesByOrganizer();
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
